import {
  Container, createStyles, Dialog, DialogContent, makeStyles, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Theme, Typography
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BugReportIcon from '@mui/icons-material/BugReport';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SettingsIcon from '@mui/icons-material/Settings';
import TaskIcon from '@mui/icons-material/Task';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import { Box } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import { useGlobalState } from '../../store/GlobalStore';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import LightTooltip from '../GlobalStyles/LightTooltip';
import data from '../ReleaseNotes/releasedata.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: "flex",
      minWidth: '100%',
      alignItems: "center",
      justifyContent: 'center',
    },
    textdisplay: {
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 12
    },
    titleStyle: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    tooltip: {
      cursor: 'pointer',
    },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "#E2E8EF",
        borderTop: 'none'
      },
      borderRadius: 30,
      padding: 2,
      marginTop: "10px",
      boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
    },
    expandicon: {
      backgroundColor: '#215176',
      color: 'white',
      borderRadius: 30,
      fontSize: 25
    },
    heading: {
      fontSize: theme.typography.pxToRem(13),
      color: "#4C4CDB"
    },
    tablebodycell: {
      backgroundColor: '#007fff',
      color: 'white',
      fontSize: 16
    },
    tablebodycell1: {
      borderBottom: '0px solid gray',
      color: 'black'
    },
    taskBox: {
      borderRadius: 10,
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      height: '140px'
    },
    taskBox1: {
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      marginTop: 5,
      '& .MuiAccordion-root:before': {
        backgroundColor: 'white',
      }
    },
    stylediv: {
      width: '55px',
      borderRadius: '10px 0px 0px 10px',
      display: 'flex',
      backgroundColor: '#215176',
      justifyContent: 'center',
      alignItems: 'center'
    },
    contentstyle: {
      flexDirection: 'column',
      display: 'flex',
      marginLeft: '10px'
    },
    iconSize: {
      color: 'white'
    },
    customTable: {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
        padding: '5px'
      }
    },
    fontHead: {
      fontSize: 14,
      fontWeight: 'bold',
      marginLeft: '10px'
    },
    fontHead1: {
      fontSize: 12,
      color: 'black'
    },
    dialogePaper: {
      minWidth: "1000px"
    },
  }),
);

const steps = [
  // 'New : New features, components or functionality',
  'Tasks :  Implemented New components and functionality',
  'Bugs : Bugs that have been fixed by dev team aware of and/or found by the ARM team',
  // 'Upcoming : Any changes/improvements that have been done to the servers'
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#55a362',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#55a362',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#55a362',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#55a362',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    // 1: <FiberNewIcon />,
    1: <SettingsIcon />,
    2: <BugReportIcon />,
    // 4: <UpcomingIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const Footer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { state } = useGlobalState();

  return (
    <React.Fragment>
      <Container className={classes.footer}>
        <Typography variant="body1" color="inherit" className={classes.textdisplay}>
          &copy;{new Date().getFullYear()} ARM SOLUTIONS | All rights reserved | 2.12.01
          &nbsp;
        </Typography>
        {state.userAccessContext?.role === 3 || state.userAccessContext?.role === 2 ?
          <React.Fragment>
            <LightTooltip title="WebView Latest Release" >
              <NewReleasesIcon style={{ fontSize: 18 }} onClick={() => { setOpen(true); }} className={classes.tooltip} />
            </LightTooltip>
          </React.Fragment>
          : null}
      </Container>

      <Dialog
        PaperProps={{ style: { borderRadius: 15, backgroundColor: '#e9ecf2' } }}
        classes={{ paper: classes.dialogePaper }}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={() => { setOpen(false); }}>
          <Typography variant="h6" gutterBottom className={classes.titleStyle}>
            Webview Release Notes
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant="h6" color="inherit" style={{ fontSize: 17 }}>
            <b>Current Version : 2.12.01</b>
          </Typography>
          <Box p={0} display="flex" style={{ gap: '15px' }}>
            <Box p={0} display="flex" width="31%" style={{ padding: '8px' }} className={classes.taskBox}>
              <div className={classes.stylediv} >
                <TaskIcon className={classes.iconSize} sx={{ fontSize: 35 }} />
              </div>
              <div className={classes.contentstyle}>
                <Typography variant="subtitle2" color="inherit" >
                  <b>July 31, 2024 - Tasks</b>
                </Typography>
                <Typography variant="caption" color="inherit" >
                  52 New<br />
                  17 Active<br />
                  0 Closed<br />
                </Typography>
              </div>
            </Box>
            <Box p={0} display="flex" width="34%" style={{ padding: '8px' }} className={classes.taskBox}>
              <div className={classes.stylediv} >
                <BugReportIcon className={classes.iconSize} sx={{ fontSize: 35 }} />
              </div>
              <div className={classes.contentstyle}>
                <Typography variant="subtitle2" color="inherit" >
                  <b>July 31, 2024 - Bugs</b>
                </Typography>
                <Typography variant="caption" color="inherit" >
                  2 New<br />
                  6 Active<br />
                  4 Awaited <br />
                  0 Onhold <br />
                  0 Closed <br />
                </Typography>
              </div>
            </Box>
            <Box p={0} display="flex" width="35%" style={{ padding: '8px' }} className={classes.taskBox}>
              <div className={classes.stylediv} >
                <UpcomingIcon className={classes.iconSize} sx={{ fontSize: 35 }} />
              </div>
              <div className={classes.contentstyle}>
                <Typography variant="subtitle2" color="inherit" >
                  <b>July 31, 2024 - Enhancement</b>
                </Typography>
                <Typography variant="caption" color="inherit" >
                  23 New <br />
                  1 Active<br />
                  0 Closed<br />
                </Typography>
              </div>
            </Box>
          </Box>

          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {data.map((item) => {
              return (
                <>
                  <Accordion elevation={0} style={{ borderRadius: 8 }} className={classes.taskBox1}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />}>
                      <Typography variant="subtitle1" className={classes.heading}>
                        <b> {item.versionname} || {item.date} &nbsp;&nbsp;
                          &nbsp; {item.description}</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                      <div>
                        <Stepper alternativeLabel activeStep={4} connector={<ColorlibConnector />}>
                          {steps.map((label) => (
                            <Step key={label} style={{ width: 200 }}>
                              <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <span className={classes.fontHead1}><b>{label}</b></span>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                        <TableContainer component={Paper} style={{ marginTop: 20, overflowX: 'hidden' }}>
                          <Table aria-label="simple table" classes={{ root: classes.customTable }}>
                            <TableHead>
                              <TableRow style={{ backgroundColor: '#007fff' }} className={classes.tablebodycell1}>
                                <TableCell width="150px" align="center" style={{ color: 'white' }}><b>{item.versionname}</b></TableCell>
                                <TableCell align="center" style={{ color: 'white' }}><b>{item.date}</b></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className={classes.tablebodycell1}>
                                <TableCell className={classes.tablebodycell}>
                                  <span className={classes.fontHead}>Tasks</span>
                                </TableCell>
                                {item.details.mode.Tasks.map((r) => {
                                  return (
                                    <>
                                      <TableRow>
                                        <TableCell width="500px" >
                                          <Typography variant="body2" className={classes.fontHead1}>
                                            {r.Description}
                                          </Typography>
                                        </TableCell>
                                        <TableCell width="500px" >
                                          <Typography variant="body2" className={classes.fontHead1}>
                                            {r.Solutions}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )
                                })
                                }
                              </TableRow>
                              <TableRow className={classes.tablebodycell1}>
                                <TableCell className={classes.tablebodycell}>
                                  <span className={classes.fontHead}>Bug Fixes</span>
                                </TableCell>
                                {item.details.mode.Bugs.map((r) => {
                                  return (
                                    <>
                                      <TableRow className={classes.tablebodycell1}>
                                        <TableCell width="500px" >
                                          <Typography variant="body2" className={classes.fontHead1}>
                                            {r.Description}
                                          </Typography>
                                        </TableCell>
                                        <TableCell width="500px" >
                                          <Typography variant="body2" className={classes.fontHead1}>
                                            {r.Solutions}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )
                                })
                                }
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </>
              )
            })}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
export default Footer
