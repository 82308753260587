import {
  Grid, IconButton, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import SearchIcon from "@material-ui/icons/Search";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import Template from "../../components/GlobalAdmin/ClientPlacementOptions/Template";
import StyledCell from "../../components/GlobalStyles/StyledCell";
import managetemplate from '../../images/template.svg';
import { IClientTemplate } from "../../models/Admin/ClientPlacements/IClientTemplate";
import { ITemplateList } from "../../models/Admin/ClientPlacements/ITemplateList";
import { usePost } from "../../utils/apiHelper";
import { IAllTemplateList } from '../../models/Admin/ClientPlacements/IAllTemplateList';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch'
      },
    },
    table: {
      minWidth: 700
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    searchBox: {
      borderRadius: "30px",
      border: '2px black solid',
      width: '95%',
      fontSize: 14,
      height: '37px',
      textIndent: '10px',
      paddingLeft: '20px',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      '@media (max-width: 600px)': {
        marginBottom: '5px',
      },
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    TextHighlight: {
      color: 'black',
      fontWeight: 'bold',
      background: "yellow",
    },
    TextNormal: {
      color: 'black',
      fontSize: 13
    },
    tablebody: {
      maxHeight: 505,
      minHeight: 505,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 210px)',
        minHeight: 'calc( 100vh - 210px)',
      },
    },
    GridTopAlign: {
      padding: '5px',
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    TableCellStyle: {
      background: "#007FFF",
      color: "white",
      fontSize: 13,
      padding: '2px 0px 2px 15px'
    },
    label: {
      '&$focused': {
        color: 'white',
        border: '0px'
      },
    },
    outlinedInput: {
      fontSize: 14,
      color: "black",
      '&$focused $notchedOutline': {
        color: 'white',
        border: '0px'
      },
    },
    notchedOutline: {
      color: 'white',
      border: '0px'
    },
    footer: {
      marginTop: '15px',
      float: 'right',
      marginRight: '60px',
      '& .MuiTypography-body1': {
        fontSize: 12,
      }
    },
    titleheader: {
      color: "blue",
      textAlign: 'left'
    },
    norecord: {
      color: "red",
      marginTop: "10px"
    },
    searchInput: {
      padding: '7px',
    },
    heading: {
      textAlign: 'left',
    },
    btnManTemp: {
      borderRadius: '20px',
      float: 'right',
      fontSize: 12,
      marginTop: '2px',
    },
    client: {
      flexGrow: 1,
      padding: '3px 8px'
    },
  })
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "clT_CODE", disablePadding: false, label: "CLIENT ID", sortable: true },
    { id: "clT_NAME_1", disablePadding: false, label: "NAME", sortable: true },
    { id: "TEMPLATE", disablePadding: false, label: "TEMPLATE", sortable: false },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id} id="CPO_TableHeader"
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.TableCellStyle}
          >
            <TableSortLabel
              id="CPO_TableSortlable"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ClientPlacementOptions() {
  const classes = useRowStyles();

  const [clientTemplateList, setClientList] = useState<IClientTemplate[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateList[]>([]);

  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);

  const [searchText, setSearchText] = useState<string>('');
  const [orderBy, setOrderBy] = useState(" ");
  const [order, setOrder] = useState("desc");

  const [ProgressBar, setshowProgressBar] = useState(true);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [open, setOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  };

  useEffect(() => {
    setshowProgressBar(true);
    getClientCodeListAsync();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (templateList.length <= 0) {
      setshowProgressBar(true);
      LoadTemplateList();
    }
  }, [templateList]);

  const getClientTemplate = () => {
    let defaultClientTemplate: ITemplateList = {
      placement_template_id: 0,
      name: 'Select Template',
    };
    return defaultClientTemplate;
  }


  async function getClientCodeListAsync() {
    setshowProgressBar(true);
    let request = {
      "query": searchText,
      "last": rowsPerPage,
      "recordNumber": (page * rowsPerPage) + 1
    }
    await usePost<{ clientDetails: IClientTemplate[], total: number }>('ClientPlacement/ClientCodeList', request).then((r) => {
      setClientList(r.data['clientDetails']);
      setRowCount(r.data.total);
    }).finally(() => {
      setshowProgressBar(false);
    });
  }

  const LoadTemplateList = () => {
    (async () => {
      let request = {
        rowsPerPage: 1000,
        startingRecordNumber: (page * rowsPerPage) + 1
      }
      await usePost<{ templateDetails: IAllTemplateList[] }>("ClientPlacement/GetAllTemplate", request).then((TemplateList) => {
        let newTemplateList: ITemplateList[] = [];
        TemplateList?.data["templateDetails"]?.map((r) => {
          let result = {
            placement_template_id: r?.placement_template_id,
            name: r?.name
          }
          newTemplateList.push(result);
        })
        newTemplateList?.unshift(getClientTemplate());
        setTemplateList(newTemplateList);
      });
    })().finally(() => {

    })
  }

  async function handleTemplateSelection(templateId: number, clientCode: string) {
    setshowProgressBar(true);
    let request = {
      "client_code": clientCode,
      "template_id": templateId
    }
    await usePost('ClientPlacement/TemplateUpdate', request).then((TemplateUpdate) => {
      setOpen(true);
    }).finally(() => {
      setshowProgressBar(false);
      getClientCodeListAsync();
    });
  }


  async function ClearClientCodeListAsync() {
    setshowProgressBar(true);
    let request = {
      "query": null,
      "last": rowsPerPage,
      "recordNumber": (page * rowsPerPage) + 1
    }
    await usePost<{ clientDetails: IClientTemplate[], total: number }>('ClientPlacement/ClientCodeList', request).then((r) => {
      setClientList(r.data['clientDetails']);
      setRowCount(r.data.total);
    }).finally(() => {
      setshowProgressBar(false);
    });
  }

  function clearSearchValues() {
    setSearchText("");
    setPage(0);
    ClearClientCodeListAsync();
    setShowSearchIcon(true);
  }

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <React.Fragment>
      <CssBaseline />
      <div id="client" className={classes.client}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.GridTopAlign}>
          <Grid xs={12} sm={4} lg={3} item>
            <Typography variant="h6" gutterBottom className={`${classes.heading} ${"headertitle"}`}>
              <b> CLIENT PLACEMENT OPTIONS </b>
            </Typography>
          </Grid>
          <Grid xs={12} sm={5} lg={7} item>
            <TextField value={searchText} variant="outlined"
              size="small"
              type='text' placeholder="Search Client ID and Client Name"
              className={classes.searchBox}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchText != "") {
                  getClientCodeListAsync(); setShowSearchIcon(false); setPage(0);
                } else if (event.key === 'Enter' && searchText === "") {
                  getClientCodeListAsync(); setShowSearchIcon(true); setPage(0);
                }
              }}
              inputProps={{ maxlength: 255 }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                  input: classes.searchInput,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={e => { getClientCodeListAsync(); setShowSearchIcon(false); setPage(0); }}>
                        <SearchIcon />
                      </IconButton>
                      :
                      <IconButton onClick={e => { clearSearchValues(); }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid xs={12} sm={3} lg={2} item>
            <Button id="CPO_EditTemp_Button" size="small" startIcon={<img src={managetemplate} alt="Icon for Manage Template" width="21px" height="21px" />} variant="contained" color="primary" component={Link} to="/GA_edittemplate" className={classes.btnManTemp}>
              Manage Templates
            </Button>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer component={Paper} className={` ${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table aria-label="customized table" size="small" stickyHeader className={` ${classes.table} ${"table-head"}`}>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(clientTemplateList, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const key = row.clT_CODE + row.clT_NAME_1
                    return (
                      <StyledTableRow key={key} hover>
                        <StyledCell component="th" scope="row">
                          <span className={`${searchText?.toUpperCase() === "" ? classes.TextNormal : row?.clT_CODE?.includes(searchText?.toUpperCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                            {row.clT_CODE}
                          </span>
                        </StyledCell>
                        <StyledCell align="left">
                          <span className={`${searchText === "" ? classes.TextNormal : row?.clT_NAME_1?.includes(searchText) ? classes.TextHighlight : classes.TextNormal}`}>
                            {row.clT_NAME_1}
                          </span>
                        </StyledCell>
                        <StyledCell align="left">
                          <Template clientCode={row.clT_CODE} selectedTemplateId={row.template_id} templateList={templateList} onTemplateSelected={handleTemplateSelection} />
                        </StyledCell>
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
            {!ProgressBar && !clientTemplateList.length ? <Typography variant="h6" gutterBottom className={classes.norecord}>
              No records to display...
            </Typography> : null}
          </TableContainer>
          <Grid container spacing={0}>
            <Grid item xs={6} sm={7}>
              <div className={classes.footer}>
                <Footer />
              </div>
            </Grid>
            <Grid item xs={6} sm={5}>
              <TablePagination
                id="CPO_TablePagination"
                rowsPerPageOptions={[15, 25, 50]}
                component="div"
                count={rowCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" className="alertStyle">
          Template Updated Successfully!
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}