import { Box, Grid, IconButton, makeStyles, TableContainer, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import { useGlobalState } from '../store/GlobalStore';
// Icon Image
import AckReportView from '../components/AckReport/AckReportView';
import webviewReport from '../images/Report/webviewreport.svg';
import requestedRepot from '../images/Report/requestedReport.svg';
import AckAdditionalReportView from '../components/AdditionalChargesAckReport/AckAdditionalReportView';

const useStyles = makeStyles((theme) => ({
    text: {
        color: "black",
        textAlign: "center",
        fontSize: '16px',
        fontWeight: 'bold',
    },
    card1: {
        display: 'block',
        width: '5.5cm',
        height: '4cm',
        marginLeft: '30px',
        marginTop: '0.5cm',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        textDecoration: 'none',
        '&:hover': {
            color: 'black',
            backgroundColor: 'white',
            borderRadius: '10px',
            transform: 'scale(1.1)',
            border: '2px blue solid',
            cursor: 'pointer',
            boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
        },
    },
    div: {
        flexGrow: 1,
    },
    adminLayout: {
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 170px)',
            minHeight: 'calc( 100vh - 170px)',
        }
    },
    image: {
        width: '70px',
        height: '70px',
        textAlign: 'center'
    },
    image1: {
        width: '90px',
        height: '70px',
        textAlign: 'center'
    },
    IconButtonripple: {
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    Tooltip: {
        fontSize: '14px',
    },
    textStyle: {
        marginTop: '5px',
        marginLeft: '15px',
        float: 'left'
    },
    TextNormal: {
        marginTop: '-180px'
    },
    footerStyle: {
        marginTop: '30px'
    },
    footerStyle1: {
        marginTop: '200px',
        float: 'left',
        marginLeft: '20px',
    }
}));

const Reports = () => {
    const classes = useStyles();
    const { state } = useGlobalState();

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
                    <b>LIST OF REPORTS</b>
                </Typography>
                <TableContainer className={` ${classes.adminLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
                    <Grid container spacing={0} >
                        <Grid item xs={6} sm={6} md={4} lg={2} >
                            <Link to="/webviewreport" className={classes.card1}>
                                <Box>
                                    <IconButton id="Webview_report" aria-label="global_message" component={Link} to="/webviewreport" className={classes.IconButtonripple}>
                                        <img src={webviewReport} alt="Webview_Report" className={classes.image} />
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h6" className={classes.text}>
                                            Webview Reports
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2} >
                            <Box className={classes.card1}>
                                <AckReportView />
                            </Box>
                        </Grid>
                        {state?.userAccessContext?.additionalChargesReport ?
                            <Grid item xs={6} sm={6} md={4} lg={2} >
                                <Box className={classes.card1}>
                                    <AckAdditionalReportView />
                                </Box>
                            </Grid>
                            : null
                        }
                        <Grid item xs={6} sm={6} md={4} lg={2} >
                            <Link to="/requestedReport" className={classes.card1}>
                                <Box>
                                    <IconButton id="requestedReport" aria-label="requestedReport" component={Link} to="/requestedReport" className={classes.IconButtonripple}>
                                        <img src={requestedRepot} alt="reportsRequerequestedReportsted" className={classes.image} />
                                    </IconButton>
                                    <Box>
                                        <Typography variant="h6" className={classes.text}>
                                            Reports Requested
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    </Grid>
                </TableContainer>
                <Footer />
            </div>
        </React.Fragment >
    );
}

export default Reports