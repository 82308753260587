import { Backdrop, Snackbar, IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useState } from 'react';
import { IAllTemplateList } from '../../../models/Admin/ClientPlacements/IAllTemplateList';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useDelete } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ClientPlacementOptionsCss';
import { PuffLoader } from 'react-spinners';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DeleteTemplate: React.FC<{ templateRow: IAllTemplateList, onDelete: () => void }> = (props) => {
  const { templateRow } = props;
  const [open, setOpen] = React.useState(false);
  const { dispatch } = useGlobalState();
  const [isCreate, setIsCreate] = React.useState(false);
  const classes = useStyles();
  const [ProgressBar, setShowProgressBar] = useState(false);

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCreate(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setIsCreate(true);
    setShowProgressBar(true);
    useDelete("File/DeleteMultipleFile?PlacementTemplateId=" + templateRow?.placement_template_id);
    useDelete("ClientPlacement/DeleteTemplate?placement_template_id=" + templateRow.placement_template_id).then((r) => {
      if (r.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: r.statusText });
      }
      props.onDelete();
    }).finally(() => {
      setShowProgressBar(false);
    });
  }

  return (
    <React.Fragment>
      <div>
        <IconButton id="CPO_Delete_btn" aria-label="delete" className={classes.deleteBtn} onClick={handleClickOpen} disabled={templateRow.placement_template_id === 1 || props?.templateRow?.canDelete ? true : false}>
          <DeleteForeverIcon className={templateRow.placement_template_id === 1 || props?.templateRow?.canDelete ? classes.deleteDisable : classes.deleteEnable} />
        </IconButton>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          open={open}
          maxWidth={"xs"}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="responsive-dialog-title" >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              DELETE TEMPLATE
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
              Are you sure, you want to delete this Template ?
            </Typography>
          </DialogContent>
          <DialogActions >
            <Button id="CPO_OK_btn" size="small" onClick={() => { handleClose(); handleOk(); }} color="primary" className={classes.yesButton} autoFocus>
              Yes
            </Button>
            <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton} autoFocus onClick={handleClose} variant="contained">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar className="snackBarStyle" open={isCreate} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={UpdateMessage}>
        <Alert onClose={UpdateMessage} severity="error" className="alertStyle">
          Template Deleted Successfully.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default DeleteTemplate