import {
  Button, Dialog, DialogActions, DialogContent, Link, makeStyles, Snackbar, Typography,
  useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import { IGetDocumentUploadLogData } from '../../models/DocumentUploadLog/GetDocumentUploadLog';
import { IDocumentDownloadFileResult } from '../../models/UploadLog/IDocumentDownloadFileResult';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  fileDownloadLink: {
    fontSize: 13,
    color: "blue",
  },
  yesbutton: {
    backgroundColor: 'green',
    fontSize: 12,
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    fontSize: 12,
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: '5px',
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

const DownloadDocumentLogFile: React.FC<{ row: IGetDocumentUploadLogData }> = (props) => {
  const classes = useStyles();
  const { row } = props
  const { state, dispatch } = useGlobalState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [fileExists, setFileExists] = useState(false);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileExists(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  function handleClose(isDownload: boolean) {
    setOpen(false);
    if (isDownload) {
      getDownloadFileAsync();
    }
  };

  function getDownloadFileAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      await useFetch<IDocumentDownloadFileResult>(`DocumentUploadLog/DocumentFileDownload?fileId=${row.file_id}`).then((r) => {
        const arrayBuffer = base64ToArrayBuffer(r.data.fileContent);
        createAndDownloadBlobFile(arrayBuffer, r.data.fileName);
      }).catch(() => {
        setFileExists(true);
      });
    })();
  }

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename) {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    // if (navigator.msSaveBlob) {
    //   // IE 10+
    //   navigator.msSaveBlob(blob, fileName);
    // } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // }
    }
  }

  return (
    <React.Fragment>
      <Typography variant="body1" display="block" className={classes.fileDownloadLink} gutterBottom>
        <Link href="#" onClick={handleClickOpen} color="inherit">
          {row.file_name}
        </Link>
      </Typography>
      <Dialog maxWidth={'sm'} PaperProps={{ style: { borderRadius: 15 } }}
        fullScreen={fullScreen} open={open}
        onClose={(_) => { handleClose(false); }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose1}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            DOWNLOAD FILE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant="h6" style={{ color: "black", fontSize: 18 }} gutterBottom>
            Are you sure, you want to Download this file ?
          </Typography>
        </DialogContent>
        <DialogActions >
          <Button variant="contained" size="small" onClick={(_) => { handleClose(true); }} className={classes.yesbutton} color="primary" autoFocus>
            Yes
          </Button>
          <Button variant="contained" size="small" autoFocus className={classes.nobutton} onClick={(_) => { handleClose(false); }} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
          File does not exists!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default DownloadDocumentLogFile