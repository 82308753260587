import SaveTemplate from '../SaveTemplate';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";
import React from 'react';
import Container from '@material-ui/core/Container';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../../components/Footer/Footer';
import Typography from '@material-ui/core/Typography';
import Processfile from '../../../images/header/process.svg';
import { Box, Button, makeStyles } from '@material-ui/core';
import { useGlobalState } from '../../../store/GlobalStore';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';
import { IClientCodeMap } from '../../../models/Placements/IClientCodeMapped';
import { IDateFormatDetails } from '../../../models/Placements/IFixedDateFormat';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 800,
    minHeight: 800,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 125px)',
      minHeight: 'calc( 100vh - 125px)',
    }
  },
  container: {
    maxWidth: "130vh",
    height: '70vh',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    overflowY: 'hidden'
  },
  messageStyle: {
    marginTop: "20px",
    fontSize: 25
  },
  actions: {
    width: "100%",
    marginTop: "1cm",
    marginRight: theme.spacing(2)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 12,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "10px",
  },
  div: {
    flexGrow: 1
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    alignContent: "center",
    color: "blue",
    marginTop: '0.2cm',
    textAlign: 'center'
  },
  finish: {
    top: '50%',
    marginTop: '1cm'
  },
  box: {
    maxWidth: "100vh",
    marginTop: '0.3cm',
    height: '20vh',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: 'white',
    overflowY: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  image: {
    height: 120,
    width: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Finish = (props) => {
  const classes = useStyles();
  let location = useLocation();
  const { state, dispatch } = useGlobalState();

  let clientCodeMappingList: IClientCodeMap[] = (props.location?.state?.clientCodeMappingList);
  let finalDateFormat: IDateFormatDetails = props?.location?.state?.finalDateFormat;
  let selectedHeaderList: string[] = props.location?.state?.selectedHeaderList;
  let clientList: IClientList[] = props.location.state?.clientList;
  let isCSVUpload: boolean = props.location.state?.isCSVUpload;
  let mBalanceValue = (props.location?.state?.mBalanceValue);
  let clientCode: string = props.location.state?.clientCode;
  let ignoreRowNumber = (props.location?.state?.ignoreRows);
  let feeValue = (props.location?.state?.feeValue);
  let savingEnable = true;
  let enable = true;

  history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    history.go(1);
  };

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <div className={classes.divStyle}>
          <Container fixed className={classes.container} component="div" >
            <Box p={1} flexShrink={0} bgcolor="white" className={classes.finish}>
              <Typography variant="h5" gutterBottom style={{ color: 'green', fontSize: 50 }}>
                <b> Thank You! </b>
              </Typography>
            </Box>
            <Box p={1} flexShrink={0} bgcolor="white" >
              <img src={Processfile} alt="Processing file for Placment Document" className={classes.image} />
            </Box>
            <div className={classes.box}>
              <Box p={1} flexShrink={0} bgcolor="white" >
                <Typography variant="h6" gutterBottom className={classes.messageStyle}>
                  We will process your accounts shortly and you can expect your acknowledgment report in 24 - 48 hours!
                </Typography>
              </Box>
            </div>
            <Box p={0} flexShrink={0} bgcolor="white" flexDirection="row" className={classes.buttonbox}>
              <Button id="Finish_Submit_btn" size="small" endIcon={<SendIcon />} className={classes.buttons} component={Link} to="/placement"
                variant="contained" color="primary">
                Submit Additional Accounts
              </Button>
              {isCSVUpload === true ?
                <SaveTemplate fees={feeValue} balance={mBalanceValue}
                  clientCode={clientCode} checkEnable={enable} selectedHeaderList={selectedHeaderList} savingTempEnable={savingEnable}
                  ignoreRowNumber={ignoreRowNumber} clientList={clientList} clientCodeMap={clientCodeMappingList} finalDateFormat={finalDateFormat} />
                : null}
            </Box>
          </Container>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Finish
