import React, { useState } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, Typography,
    Grid, makeStyles, TextField, Box
} from '@material-ui/core';
import * as _ from 'lodash';
import "react-multi-email/style.css";
// Icons
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
// Models
import { Autocomplete } from '@material-ui/lab';
import { useFetch, usePost } from '../../utils/apiHelper';
import { IMappingDetail } from '../../models/Placements/IMappingDetail';
import { IMappingCreate } from '../../models/Placements/IMappingCreate';
import { IClientCodeMap } from '../../models/Placements/IClientCodeMapped';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { IMappingTemplate } from '../../models/Placements/IMappingTemplate';
import { IClientList } from '../../models/Admin/ClientPlacements/IClientList';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import { IGetPlacementMapId } from '../../models/Placements/IGetPlacementMapId';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';
import { IDateFormatDetails } from '../../models/Placements/IFixedDateFormat';
import * as constant from '../../constants/Constant';

const useStyles = makeStyles((theme) => ({
    button: {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: 165,
        borderRadius: 10,
        marginTop: "-40px"
    },
    buttons: {
        borderRadius: 20,
        fontSize: 12,
        fontWeight: 'bold',
        background: "#007FFF",
        color: "white",
        marginTop: "10px",
        marginLeft: '20px'
    },
    box: {
        display: "flex",
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    mapfiled: {
        marginTop: '1cm',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    confirmButton: {
        color: "white",
        marginTop: "7px",
        fontSize: 12,
        right: 20,
        borderRadius: 20,
    },
    cancel: {
        background: "red",
        color: "white",
        marginTop: "7px",
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
}))

const SaveTemplate: React.FC<{
    fees: any, balance: any, clientCode: string, onTemplateSave?: () => void, checkEnable: boolean,
    selectedHeaderList: string[], ignoreRowNumber: number, savingTempEnable: boolean, clientList: IClientList[], clientCodeMap: IClientCodeMap[],
    finalDateFormat: IDateFormatDetails
}> = (props) => {
    const classes = useStyles();
    const { fees, balance, clientCode, checkEnable, selectedHeaderList, ignoreRowNumber, clientList, savingTempEnable, clientCodeMap, finalDateFormat } = props
    const [open, setOpen] = React.useState(false);
    const { state, dispatch } = useGlobalState();
    const [Share, setShare] = useState(true);
    const [TemplateName, setTemplateName] = useState("");
    const [error, setError] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [selectedClientCode, setSelectedClientCode] = useState<IClientList>();
    const [isMulClient, setIsMulClient] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function emptyCheck() {
        setOpen(false);
        setShare(false);
        setError("");
        setTemplateName('');
        setSelectedClientCode({ clT_NAME_1: "", clT_CODE: "" });
        setIsMulClient(true);
    }

    const handleTemplateNameChange = (event) => {
        HandleReportNameVal(event.target.value);
        let tempName = event.target.value;
        if (tempName === '' || tempName === undefined) {
            setShare(true);
        } else {
            setShare(false);
        }
    }

    const HandleReportNameVal = (template) => {
        const newValue = template;
        if (!newValue.match(/[~`!@#^&*()+=%<>?.,:;{}|\\$'"]/)) {
            setError("");
            setIsValid(true);
            setTemplateName(newValue);
        } else {
            setError("please enter characters and numbers only");
            setIsValid(false);
        }
    };

    const handleClientChange = (event, selected) => {
        const selectedValue = clientList.find(x => x.clT_CODE === selected?.clT_CODE);
        if (selected) {
            setSelectedClientCode(selectedValue);
            setIsMulClient(false);
        } else {
            setSelectedClientCode({ clT_NAME_1: "", clT_CODE: "" });
            setIsMulClient(true);
        }
    };

    async function CreateMappingAsync() {
        if (!isInputValid()) {
            setError("Template name is required");
        } else {
            let mappingTemplate: IMappingTemplate = {
                client_code: clientCode == "-2" ? selectedClientCode.clT_CODE : clientCode,
                map_name: TemplateName,
                fees: Number(fees),
                balance: Number(balance),
                rows_to_ignore: ignoreRowNumber,
                placement_map_id: null
            };
            let mappingDetails: IMappingDetail[] = [];
            let dateFormat = null;
            selectedHeaderList.forEach((x, index) => {
                if (x === constant.delinquency_date) {
                    dateFormat = finalDateFormat?.delinquency_date;
                } else if (x === constant.Itemization_Date) {
                    dateFormat = finalDateFormat?.Itemization_Date;
                } else if (x === constant.birth_date){
                    dateFormat = finalDateFormat?.birth_date
                } else if(x === constant.last_payment_date){
                    dateFormat = finalDateFormat.last_payment_date
                } else if(x === constant.service_date){
                    dateFormat = finalDateFormat.service_date
                } else if(x === constant.Codebtor_BirthDate){
                    dateFormat = finalDateFormat.Codebtor_BirthDate
                }

                if (x && x != "-1") {
                    let mappingDetail: IMappingDetail = {
                        field_name: x,
                        ordinal_position: index,
                        date_format: dateFormat
                    }
                    dateFormat = null;
                    mappingDetails.push(mappingDetail);
                }
            });

            let request: IMappingCreate = {
                mappingTemplate: _.cloneDeep(mappingTemplate),
                mappingDetails: _.cloneDeep(mappingDetails)
            }

            try {
                await usePost<IMappingTemplate>('Placements/MappingCreate', request);
                emptyCheck();
                if (clientCode == "-2") {
                    await useFetch<IGetPlacementMapId>('Placements/GetPlacementMapId').then((r) => {
                        clientCodeMap.forEach(async (x, index) => {
                            let clientCodeMapping: IClientCodeMap = {
                                placement_map_id: r?.data?.placement_map,
                                client_code: x?.client_code,
                                client_indicators: x?.client_indicators
                            }
                            await usePost<IClientCodeMap>('ClientPlacement/CreateClientCodeMapping', clientCodeMapping);
                        });
                    });
                }
                props.onTemplateSave();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        }
    }

    function isInputValid(): boolean {
        let selectedCode = clientCode == "-2" ? selectedClientCode?.clT_CODE : clientCode;
        if (selectedCode && TemplateName) {
            return isValid;
        }
        return false;
    }

    return (
        <React.Fragment>
            <Button id="PLC_Back_btn" className={classes.buttons} size="small" startIcon={<SaveIcon />}
                onClick={handleClickOpen} disabled={!checkEnable || !savingTempEnable} variant="contained" color="primary" >
                Save mapping Template ?
            </Button>
            <Dialog TransitionComponent={Transition} open={open} onClose={(_) => { setOpen(false); }} maxWidth={'sm'}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        SAVE MAPPING TEMPLATE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent  >
                    <Grid item xs={12}>
                        <TextField id="CR_Name" size="small" className={classes.mapfiled}
                            onChange={(e) => { handleTemplateNameChange(e); }}
                            placeholder="Enter Mapping Name" fullWidth margin="normal" variant="outlined"
                            value={TemplateName} required helperText={error} error={!!error} inputProps={{ maxLength: 100 }}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </Grid>
                </DialogContent>
                {clientCode == "-2" ?
                    <DialogContent >
                        <Grid item xs={12}>
                            <Autocomplete
                                id="clientCode-select"
                                size="small"
                                style={{ width: 400 }}
                                options={clientList}
                                autoHighlight
                                onChange={handleClientChange}
                                getOptionLabel={(option) => option.clT_CODE == "-2" ? option.clT_NAME_1 : option.clT_CODE + "--" + option.clT_NAME_1}
                                getOptionSelected={(option, value) => option.clT_CODE === value.clT_CODE}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.clT_CODE == "-2" ? option.clT_NAME_1 : option.clT_CODE + "--" + option.clT_NAME_1}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select client"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }} />
                                )} />
                        </Grid>
                    </DialogContent>
                    : null
                }
                <Box component="span" m={2}>
                    <DialogActions>
                        <Button id="RS_send_btn" size="small" variant="contained" className={classes.confirmButton} startIcon={<DoneAllIcon />}
                            onClick={(_) => { CreateMappingAsync(); }} disabled={clientCode === "-2" ? isMulClient : Share}
                            style={clientCode === "-2" ? (isMulClient ? { color: "white", marginTop: "2px" } : { background: "green", color: "white" }) : (Share ? { color: "white", marginTop: "2px" } : { background: "green", color: "white" })}>
                            Submit
                        </Button>
                        <Button id="RS_Cancel_btn" size="small" variant="outlined" onClick={(_) => { setShare(false); emptyCheck(); }} startIcon={<CancelIcon />}
                            className={classes.cancel}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </React.Fragment >
    )
}

export default SaveTemplate