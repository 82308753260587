import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import Useraccess from '../images/admin/useraccess.svg';
import Conformation from '../images/settings/conformation.svg';
import prospectusers from '../images/users/prospectusers.svg';
import Information from '../images/settings/information.svg';
import { UserRole } from '../models/accesscontrol/ClientUserAccessContext';
import { useGlobalState } from '../store/GlobalStore';

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    marginTop: '2cm',
    alignItems: "center",
    borderRadius: 20,
  },
  text: {
    color: "black",
    textAlign: "center",
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: 16
  },
  card1: {
    display: 'block',
    width: '5.5cm',
    height: '4cm',
    marginLeft: '30px',
    marginTop: '0.5cm',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: 'black',
      backgroundColor: 'white',
      borderRadius: '10px',
      transform: 'scale(1.1)',
      border: '2px blue solid',
      cursor: 'pointer',
      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
    },
  },
  div: {
    flexGrow: 1,
  },
  adminLayout: {
    maxHeight: 800,
    minHeight: 800,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 125px)',
      minHeight: 'calc( 100vh - 125px)',
    }
  },
  image: {
    width: '70px',
    height: '70px',
    textAlign: 'center'
  },
  IconButtonripple: {
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  Tooltip: {
    fontSize: '14px',
  },
  girdStyle: {
    alignContent: 'center',
    minHeight: 500
  },
  textStyle: {
    marginTop: '5px',
    marginLeft: '15px',
    float: 'left'
  }
}));

const Settings = () => {
  const classes = useStyles();
  const { state } = useGlobalState();
  const UserAccessPermission = state.userAccessContext?.role == UserRole?.ClientUser || state.userAccessContext?.role == UserRole?.ClientAdmin;
  const canAccessclientConfirmation = state.userAccessContext?.clientConfirmation;

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Box className={classes.adminLayout}>
          <Grid container spacing={1} alignItems="center" >
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
                <b>SETTINGS </b>
              </Typography>
            </Grid>

            {state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ?
              <Grid item xs={6} sm={6} md={4} lg={2} >
                <Link to="/clientinformation" className={classes.card1}>
                  <Box >
                    <IconButton id="ClientInformation_Button" aria-label="client_information" component={Link} to="/clientinformation" className={classes.IconButtonripple}>
                      <img src={Information} alt="Settings_Client_Information" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        Client Information
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
              :
              null
            }

            {(state.userAccessContext?.role === 0 || state.userAccessContext?.role === 1) && canAccessclientConfirmation ?
              <Grid item xs={6} sm={6} md={4} lg={2} >
                <Link to="/clientconfirmation" className={classes.card1}>
                  <Box>
                    <IconButton id="ClientConfirmation_Button" aria-label="client_conformation" component={Link} to="/clientconfirmation" className={classes.IconButtonripple}>
                      <img src={Conformation} alt="Settings_Client_Confirmation" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        Client Confirmation
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
              :
              (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) ?
                <Grid item xs={6} sm={6} md={4} lg={2} >
                  <Link to="/clientconfirmation" className={classes.card1}>
                    <Box>
                      <IconButton id="ClientConfirmation_Button" aria-label="client_conformation" component={Link} to="/clientconfirmation" className={classes.IconButtonripple}>
                        <img src={Conformation} alt="Settings_Client_Confirmation" className={classes.image} />
                      </IconButton>
                      <Box>
                        <Typography variant="h6" className={classes.text}>
                          Client Confirmation
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
                :
                null
            }

            {UserAccessPermission ?
              <Grid item xs={6} sm={6} md={4} lg={2} >
                <Link to="/useraccesscontrol" className={classes.card1}>
                  <Box >
                    <IconButton id="UserAccessContext_Button" aria-label="user_access_file" component={Link} to="/useraccesscontrol" className={classes.IconButtonripple}>
                      <img src={Useraccess} alt="Settings_User_Access_Control" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        User Access Control
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
              :
              null
            }

            {state?.userAccessContext?.role === 1 ?
              <Grid item xs={6} sm={6} md={4} lg={2} >
                <Link to="/prospectUsersList" className={classes.card1}>
                  <Box>
                    <IconButton id="Admin_Page_ProspectUser" aria-label="prospectuser" component={Link} to="/prospectUsersList" className={classes.IconButtonripple}>
                      <img src={prospectusers} alt="Admin_Page_ProspectUser" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        Prospect Users
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
              : null
            }
          </Grid>
        </Box>
        <Footer />
      </div>
    </React.Fragment >
  )
}

export default Settings