import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import React from 'react';
import { IAgentExportReport } from '../../../models/AgentReport/IAgentReportContract';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
        },
        rootHidden: {
            display: "none"
        },
        collapsePadding: {
            padding: '8px'
        },
        tablefont: {
            fontSize: 12,
            padding: '5px 0px 5px 5px !important'
        }
    })
);

const AgentExpandableRow: React.FC<{ row: IAgentExportReport }> = (props) => {
    const classes = useRowStyles();
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow >
                <TableCell className={classes.tablefont}>{row?.clientCode}</TableCell>
                <TableCell className={classes.tablefont}>{row?.emailAddress}</TableCell>
                <TableCell className={classes.tablefont}>{row?.fullName}</TableCell>
                <TableCell className={classes.tablefont}>{row?.communicationMethod}</TableCell>
                <TableCell className={classes.tablefont}>{row?.activityName}</TableCell>
                <TableCell className={classes.tablefont}>{row?.totalCount}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default AgentExpandableRow;